.confirmDelete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 5px 10px #888888;
  box-sizing: border-box;
  flex-direction: column;

  .close {
    position: absolute;
    right: -27px;
    top: -28px;
    opacity: 0.3;
    cursor: pointer;
    width: 25px;
    height: 29px;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  .content {
    color: black;
    font-weight: bold;

    .mark {
      color: red;
    }
  }
  .buttonWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;

    .btn {
      width: 100%;
      padding: 4px;
      font-weight: bold;
      border: 1px solid black;
      border-radius: 4px;
      cursor: pointer;
    }
    .delete {
      margin-left: 15px;
      color: red;

    }
  }
}
