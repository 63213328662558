.catalog {
  display: flex;
  margin-left: 5px;
  flex-direction: column;

  .upperBlock {
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    height: 30px;
    border-bottom: 1px solid black;
    margin-top: 10px;
    width: calc(100% - 25px);
    padding: 0 15px 0 10px;

    .firstBlock {
      display: flex;

      .id {
        margin-right: 40px;
      }
    }
    .secondBlock {
      display: flex;

      .id {
        margin-right: 80px;
      }
      .color {
        margin-right: 50px;
      }
      .deleteBtn {
        width: 160px;
        background: red;
        height: 21px;
        color: white;
        border-radius: 2px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .stock {
      margin-left: 114px;
    }
  }
  .item {
    display: flex;
    align-items: center;
    width: calc(100% - 25px);
    padding: 0 15px 0 10px;
    border-bottom: 1px solid #8a8889;
    height: 35px;
    justify-content: space-between;
    .editStock {
      width: 45px;
      text-align: left;
      margin-right: 40px;
      color: #8a8889;
      font-size: 15px;
      box-sizing: border-box;
    }
    .idModel {
      color: #8a8889;
      font-size: 15px;
      white-space: nowrap;
      margin-right: 52px;
      width: 124px;
    }
    .editId {
      width: 100px;
      margin-right: 60px;
    }
    .id {
      width: 30px;
      text-align: left;
      margin-right: 37px;
      color: #8a8889;
      font-weight: bold;
      font-size: 15px;
    }
    .name {
      width: 220px;
      text-align: left;
      color: #8a8889;
      font-weight: bold;
      font-size: 15px;
    }
    .stock {
      width: 45px;
      text-align: left;
      //margin-right: 130px;
      color: #8a8889;
      font-size: 15px;
      white-space: nowrap;
    }
    .outStock {
      color: red;
    }
    .color {
      width: 50px;
      height: 20px;
      margin-right: 40px;
    }
    .btnWrap {
      display: flex;
      width: 160px;
      justify-content: space-between;

      .editBtn {
        background: green;
        width: 100%;
        margin-right: 10px;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        cursor: pointer;
      }
      .deleteBtn {
        background: red;
        width: 100%;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}
