.wrapConfig {
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  .collectionWrapper {
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .addModelBtn {
      text-transform: uppercase;
      border: none;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 40px;
      font-size: 12px;
    }
    .enable {
      color: white;
      background: #e10000;
      cursor: pointer;
    }
    .disable {
      color: #b6a5a5;
      background: #4f0b15;
    }
  }
}
