.complete {
  .wrapText {
    p {
      font-weight: 500;
      font-size: 14px;
    }
  }
  .wrapBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      margin: 10px;
    }
    .btn {
      color: white;
      background: #810416;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      height: 40px;
      cursor: pointer;
      text-transform: uppercase;
      margin: 0;
    }
    .first {
      font-size: 14px;
    }
    .second {
      font-size: 12px;
      text-decoration: none;
    }

  }
}
