.selectedCollection {
  width: 200px;
  background: #8a8889;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 12px;

  .arrowTop {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -17px;
    background: white;

    .triangle {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 14px solid gray;
      position: absolute;
      top: 6px;
    }
  }
  .arrowBottom {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: -17px;
    background: white;

    .triangle {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 14px solid gray;
      position: absolute;
      bottom: 6px;
    }
  }

  .borderTop {
    border-top: 2px solid #292929;
    width: 150px;
    margin: 22px 0 10px 0;
  }
  .preview {
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    //margin-left: 8px;

    ul {
      list-style: none;
      padding: 0;
      height: 100%;
      padding-left: 8px;

      .active {
        border: 2px solid green !important;
      }
      .missing {
        opacity: 0.5;
      }
      div {
        border: 2px solid transparent;
      }
      div:not(:last-child) {
        margin-bottom: 10px;
      }

      img {
        width: 100px;
        height: 100px;
        display: block;
      }
    }
  }
  .preview::-webkit-scrollbar {
    //background: #8a8889;
    background: none;
    width: 5px;
  }
  .preview::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 60px;
  }
  .borderBottom {
    width: 150px;
    border-top: 2px solid #292929;
    margin: 10px 0 22px 0;
  }
}
