.configurator {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .configWrap {
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
  }
}
