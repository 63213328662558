.uploader {
  width: calc(100% - 37px);
  display: flex;
  align-items: center;
  border: 1px solid #8a8889;
  height: 70px;
  padding: 0 15px 0 15px;
  justify-content: space-between;
  margin-left: 5px;

  .upload3D {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .modelFormat {
      color: grey;
      font-size: 11px;
    }

    div {
      margin-bottom: 10px;
    }

    ::-webkit-file-upload-button {
      cursor:pointer;
    }

  }
  .uploadImage {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .modelFormat {
      color: grey;
      font-size: 11px;
    }
    div {
      margin-bottom: 10px;
    }
    ::-webkit-file-upload-button {
      cursor:pointer;
    }
  }
  .selectColor {
    position: relative;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      margin-bottom: 10px;
    }
    .color {
      background: black;
      width: 100px;
      height: 20px;
      cursor: pointer;
    }
    .colorPicker {
      display: none;
      position: absolute;

      .close {
        position: absolute;
        right: -14px;
        top: -25px;
        width: 17px;
        height: 20px;
        opacity: 1;
        cursor: pointer;
      }
      .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #333;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    }
    .active {
      display: flex !important;
      top: 49px;
      left: -52px;
      z-index: 2;
    }
  }
  .selectId {
    .title {
      font-size: 12px;
      margin-bottom: 10px;
    }
    input {
      border: none;
      width: 130px;
      text-align: center;
      border-bottom: 1px solid black;
      outline: none;
    }
  }
  .stock {
    .title {
      font-size: 12px;
      margin-bottom: 10px;
    }
    input {
      border: none;
      width: 50px;
      text-align: center;
      border-bottom: 1px solid black;
      outline: none;
    }
  }
  .uploadBtn {
    background: green;
    width: 80px;
    color: white;
    font-size: 14px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2px;
  }
}
