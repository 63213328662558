.selectCollection {
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 306px;
  background: #cccccc;
  border-radius: 10px;
  position: relative;

  &-par {
    font-weight: bold;
    cursor: pointer;
    color: black;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;

    :nth-child(1) {
      cursor: default;
    }
  }
  &-par:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .btn {
    cursor: auto !important;
    padding: 10px 0 10px 0;
  }
  .active {
    background: green;
    color: white;
  }
  .triangle {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid green;

    position: absolute;
    right: -11px;
    z-index: 1;
    transform: translateY(66px);
  }
}
