.canvasComponent {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .canvas {
    width: 400px !important;
    height: 450px !important;
    background: black;
  }
  .colorButtonWrapper {
    position: relative;
    .colorEditBtn {
      margin-top: 5px;
      background: #4f0b15;
      border-radius: 20px;
      font-size: 16px;
      padding: 10px;
      border: none;
    }
    .enable {
      cursor: pointer;
      color: white;
    }
    .disable {
      color: #b6a5a5;
    }
    .cancelChangesBtn {
      margin-top: 5px;
      background: #4f0b15;
      border-radius: 20px;
      font-size: 16px;
      padding: 10px;
      border: none;
      cursor: pointer;
      color: white;
    }
    .colorPicker {
      display: none;
      position: absolute;
      top: 5px;
      left: 155px;
      .close {
        position: absolute;
        right: -18px;
        top: -20px;
        width: 24px;
        height: 20px;
        opacity: 1;
        cursor: pointer;
      }
      .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #333;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    }
    .active {
      display: block;
    }
  }
}
