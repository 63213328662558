.collection {
  height: calc(100vh - 49px);
  background: #cccccc;
  &-par {
    height: 70px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-bottom: 2px solid white;
    cursor: pointer;
  }
  .first {
    border-top: 2px solid white;
  }
  .active {
    background: #12643e;
  }
}
