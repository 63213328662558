.adminPage {
  display: flex;
  flex-direction: column;

  .header {
    background: #cccccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    height: 30px;
  }
  .mainWrapper {
    display: flex;

    .uploaderWrapper {
      width: 100%;
    }
  }
}
